module.exports = {
    info: {
        socialMediaUrls: {
            facebook: "https://www.facebook.com/profile.php?id=100089745683275",
            instagram: "https://www.instagram.com/aljamili.trading/",
            //xTwitter: "#xtwitter",
            tiktok: "https://www.tiktok.com/@aljamiligenraltrading",
            //linkedIn: "#linkedIn",
            //youtube: "#youtube",
            whatsApp: "https://wa.me/963991717836",
            googleMaps: "https://maps.app.goo.gl/DbcM3JjGyKVn8T856"
        },
    
        socialMediaIframesSrcs: {
            news: "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100089745683275&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId", // Facebook or Twitter Feeds
            googleMaps: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3194.7307042280795!2d34.63225057381898!3d36.80100660403594!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDQ4JzA0LjUiTiAzNMKwMzcnNTQuNyJF!5e0!3m2!1sen!2str!4v1723833584985!5m2!1sen!2str", // google_maps_address_embed_url
        },
    
        contact: {
            emailAddress: "info@aljamili-trading.com",
    
            phoneNumber: "+905398949566",
            phoneNumberTxt: "(+90) 539 8949 566",
    
            phoneNumber2: "+905385977095",
            phoneNumber2Txt: "(+90) 538 597 70 95",
    
            address: "Camişerif Mah. İstiklal Cad. Kuriş Ofisi. No: 26/3 Akdeniz, Mersin, Türkiye."
        },
    
        site: {
            websiteUrl: "https://www.aljamili-trading.com",
            title: "Aljamili General Trading",
            description: "Aljamili General Trading Company Ltd. High Quality Products.",
            author: "ITDBase.Com",
            owner: "Aljamili General Trading Ltd.",
            copyright: "Aljamili General Trading Ltd.",
            keywords: "Aljamili, General, Trading, Company, Ltd, High, Quality, Legumes, Import, Export, Turkey, Yüksek, Kalite, Bakliyat, İthalat, İhracat, Türkiye,شركة,الجميلي,تجارة,عالية,الجودة,بقوليات,استيراد,تصدير,تركيا,مرسين",
            address: {
                street: "Camişerif Mah. İstiklal Cad.",
                city: "Akdeniz",
                region: "Mersin",
                postalCode: "33060",
                countryCode: "TR",
            }
        },
    },
    defaultLanguage: "en", // If browser detection didn't work, fallback language
}